
  import Campaign from 'models/campaign'

  import WhatsappTemplateDisplay from './whatsapp_template_display.vue'
  import { Alert, Button, H3, Input } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon, PencilIcon } from '@heroicons/vue/outline'

  export default
    props:
      campaign: Campaign

    data: ->
      editing: false
      phoneNumberForPreview: ''
      previewErrorMessage: null
      debounceTimeout: null
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      PencilIcon: PencilIcon

    computed:
      template: ->
        @$root.templates[@campaign.channelId].find (template) => template.name == @campaign.template.templateName

    methods:
      sendPreview: ->
        return if @phoneNumberForPreview.length == 0
        @previewErrorMessage = null
        @campaign.preview(@phoneNumberForPreview)
          .catch (data) =>
            @previewErrorMessage = data.message if data.message?
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@campaign.save, 1000)

    components:
      Alert: Alert
      Button: Button
      H3: H3
      Input: Input
      WhatsappTemplateDisplay: WhatsappTemplateDisplay


  import WhatsappTemplate from 'models/whatsapp_template'
  import WhatsappTemplateParameter from 'models/whatsapp_template_parameter'
  import TemplateMessage from 'models/template_message'

  import WhatsappTemplateParameterEditor from './whatsapp_template_parameter_editor.vue'
  import { H3 } from 'components/generic'

  export default
    props:
      modelValue: TemplateMessage
      template: WhatsappTemplate

    data: ->
      templateMessage: @modelValue

    computed:
      noParameters: ->
        @templateMessage.headerParameters.length + @templateMessage.bodyParameters.length + @templateMessage.footerParameters.length + @templateMessage.buttonParameters.length == 0

    watch:
      template: ->
        @reSetParameters()

    created: ->
      @setParameters() if @template?

    methods:
      setParameters: ->
        @template.headerParameters.forEach (templateParam, index) =>
          @templateMessage.headerParameters[index] ||= templateParam
          @templateMessage.headerParameters[index].type = templateParam.type
          @templateMessage.headerParameters[index].placeholder = templateParam.placeholder
        @template.bodyParameters.forEach (templateParam, index) =>
          @templateMessage.bodyParameters[index] ||= templateParam
          @templateMessage.bodyParameters[index].placeholder = templateParam.placeholder
        @template.footerParameters.forEach (templateParam, index) =>
          @templateMessage.footerParameters[index] ||= templateParam
          @templateMessage.footerParameters[index].placeholder = templateParam.placeholder
        @template.buttonParameters.forEach (templateParam, index) =>
          @templateMessage.buttonParameters[index] ||= templateParam
          @templateMessage.buttonParameters[index].placeholder = templateParam.placeholder
      reSetParameters: ->
        @templateMessage.headerParameters = @template.headerParameters
        @templateMessage.bodyParameters = @template.bodyParameters
        @templateMessage.footerParameters = @template.footerParameters
        @templateMessage.buttonParameters = @template.buttonParameters
      update: ->
        @$emit('update:modelValue', @templateMessage)

    components:
      H3: H3
      WhatsappTemplateParameterEditor: WhatsappTemplateParameterEditor


  import Campaign from 'models/campaign'

  import { Button, H4, Input } from 'components/generic'
  import { ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      campaign: Campaign

    data: ->
      ChevronRightIcon: ChevronRightIcon

    computed:
      nextStepPossible: ->
        @campaign.label.length && @campaign.description.length

    mounted: ->
      @$refs.labelInput.focus()

    methods:
      nextStep: ->
        return unless @nextStepPossible
        @campaign.save()
          .then => @$emit('next')

    components:
      Button: Button
      H4: H4
      Input: Input


  import Audience from 'models/audience'
  import Campaign from 'models/campaign'

  import ConditionEditor from 'components/botbuilder/condition_editor.vue'
  import { Api2PaginationWithNumbers, Button, H4, Spinner, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      audience: Audience
      campaign: Campaign

    data: ->
      showConditionEditor: true
      debounceTimeout: null
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon

    computed:
      nextStepPossible: ->
        true

    mounted: ->
      if @audience.id
        @$refs.pagination.load()
      else
        @audience.save()
          .then =>
            @campaign.audienceId = @audience.id
            @campaign.save()
            @$refs.pagination.load()

    methods:
      resetCondition: ->
        @showConditionEditor = false
        @audience.resetCondition()
        @update()
        @$nextTick => @showConditionEditor = true
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          @audience.save()
            .then => @$refs.pagination.load()
        , 1000)
      nextStep: ->
        return unless @nextStepPossible
        @campaign.audienceId = @audience.id
        @campaign.save()
          .then => @$emit('next')

    components:
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      Button: Button
      ConditionEditor: ConditionEditor
      H4: H4
      Spinner: Spinner
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper


  import Campaign from 'models/campaign'
  import PlatformApi from 'models/platform_api'

  import { ActionMenu, Badge, TableCell, TableRow } from 'components/generic'
  import { alert, confirm } from 'helpers'
  import { ChartSquareBarIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      campaign: Campaign

    emits: ['remove']

    data: ->
      actionMenuItems: [
        {icon: XIcon, label: 'Delete', method: @remove},
      ]

    computed:
      channel: ->
        @$root.channels.find (ch) => ch.id == @campaign.channelId
      phoneNumber: ->
        @channel?.nativeId
      routerTarget: ->
        {name: 'campaign', params: {campaignId: @campaign.id}}

    methods:
      remove: ->
        return unless await confirm("Do you really want to delete campaign “#{@campaign.label}”?")
        @campaign.delete()
          .then => @$emit('remove')

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      ChartSquareBarIcon: ChartSquareBarIcon
      TableCell: TableCell
      TableRow: TableRow
      XIcon: XIcon

import { railsEnv } from 'rails_data'
import Channel from 'models/channel.coffee'
import Environment from 'models/environment.coffee'

import { alert } from 'helpers'

BASE_URL = if railsEnv == 'production' then 'https://api.mercury.ai/v2/' else 'https://api.mercury.ai/beta2/'

export default class PlatformApi2
  @baseCall: (method, endpoint, {params, body, headers, token} = {}) ->
    params ||= {}
    token ||= Globals.project?.token
    new Promise (resolve, reject) =>
      queryString = if Object.keys(params).length
        '?' + Object.keys(params)
          .map((k) -> k + '=' + encodeURIComponent(params[k]))
          .join('&')
      else
        ''
      url = BASE_URL + endpoint + queryString
      fetch(
        url,
        method: method.toUpperCase(),
        body: if body? then JSON.stringify(body) else null,
        headers: Object.assign(
          'X-Requested-With': 'XMLHttpRequest'
          'Content-Type': 'application/json'
          'Accept': 'application/json'
          'Authorization': "Bearer #{token}"
        , headers)
      )
        .then (response) ->
          if response.ok
            resolve(response)
          else if response.status == 401
            reject(
              success: false
              message: "You are not authorized to access the requested resource.\n(#{method.toUpperCase()} #{url})"
              status: response.status
            )
          else
            response.json().then (data) -> reject(Object.assign(data, status: response.status))
        .catch(reject)

  @call: (method, endpoint, {params, body, noAlert} = {}) =>
    new Promise (resolve, reject) =>
      projectId = Globals.project?.uuid
      if !projectId?
        console.error('No project id set.')
        reject()
      @baseCall(method, "projects/#{projectId}/#{endpoint}", params: params, body: body)
        .then (response) ->
          return {data: {status: 202}} if response.status == 202
          response.json()
        .then (response) -> resolve(response.data)
        .catch (response) ->
          await alert(response.message) if response.status != 404 && !noAlert
          reject(response)

  @postMultipart: (endpoint, {name, file} = {}) =>
    new Promise (resolve, reject) =>
      projectId = Globals.project?.uuid
      if !projectId?
        console.error('No project id set.')
        reject()
      token = Globals.project?.token
      formData = new FormData()
      formData.append(name || 'file', file)
      fetch(
        BASE_URL + "projects/#{projectId}/" + endpoint,
        method: 'POST',
        body: formData,
        headers:
          'X-Requested-With': 'XMLHttpRequest'
          'Authorization': "Bearer #{token}"
      )
        .then (response) ->
          new Promise (resolve, reject) =>
            if response.ok
              resolve(response)
            else
              response.json().then (data) -> reject(Object.assign(data, status: response.status))
        .then (response) -> response.json()
        .then (response) -> resolve(response.data)
        .catch (response) ->
          await alert(response.message) if response.status != 404
          reject(response)

  @getChannels: =>
    @call('get', 'channels')
      .then (data) ->
        data
          .filter (channelData) -> channelData.retired != true
          .map (channelData) -> Channel.typed(channelData)
  @getChannel: (id) =>
    @call('get', "channels/#{id}")
      .then (channelData) -> Channel.typed(channelData)
  @createChannel: (channel) =>
    @call('post', 'channels', body: channel.export)
  @updateChannel: (channel) =>
    @call('patch', "channels/#{channel.id}", body: channel.export)
  @deleteChannel: (channel) =>
    @call('delete', "channels/#{channel.id}")
  @connectChannel: (channel, credentials) =>
    @call('post', "channels/#{channel.id}/connect", body: credentials)
  @getChannelConfig: (channel) =>
    @call('get', "channels/#{channel.id}/config")
  @updateChannelConfig: (channel) =>
    @call('patch', "channels/#{channel.id}/config", body: channel.config)
  @uploadChannelAsset: (channel, name, file) =>
    @postMultipart("channels/#{channel.id}/assets/#{name}", file: file)
  @deleteChannelAsset: (channel, name) =>
    @call('delete', "channels/#{channel.id}/assets/#{name}")

  @getEnvironments: =>
    @call('get', 'environments')
      .then (data) -> data.map (envData) -> new Environment(envData)
  @getDeploymentSettings: =>
    @call('get', 'deployments/settings')
  @updateDeploymentSettings: (settings) =>
    @call('patch', 'deployments/settings', body: settings)
  @approveDeployment: (deploymentId) =>
    @call('post', "deployments/#{deploymentId}/approve")
  @setActiveBot: (botId) =>
    @call('post', 'deployments/activebot', body: {botId: botId})
  @getActiveBotId: ({noAlert} = {}) =>
    @call('get', 'deployments/activebot', noAlert: noAlert)
      .then (data) -> data.botId

  @getIntents: (params = {}) =>
    @call('get', 'nlu/intents', params: params)
  @getTrainingData: (params = {}) =>
    @call('get', 'nlu/trainingdata', params: params)
  @getInterpretation: (params = {botId, language, utterance}) =>
    @call('get', 'nlu/interpret', params: params)

  @getNoParseMessages: ({environment} = {}) =>
    @call('get', 'messages/lowparse', params: {environment: environment})
  @deleteNoParseMessage: (message) =>
    @call('delete', "messages/lowparse/#{message.id}")

  # users
  @renderMessage: (conversation, message) ->
    @call('post', "users/#{conversation.id}/render_message", body: message.export, noAlert: true)

  # secrets
  @getSecrets: =>
    @call('get', 'vault/secrets')
  @createSecret: (secret) =>
    @call('post', 'vault/secrets', body: secret.export)
  @deleteSecret: (secret) =>
    @call('delete', "vault/secrets/#{secret.id}")

  # audiences
  @getAudiences: =>
    @call('get', 'insights/audiences')
  @getAudience: (id) =>
    @call('get', "insights/audiences/#{id}")
  @createAudience: (audience) =>
    @call('post', 'insights/audiences', body: audience.export)
  @updateAudience: (audience) =>
    @call('patch', "insights/audiences/#{audience.id}", body: audience.export)
  @deleteAudience: (audience) =>
    @call('delete', "insights/audiences/#{audience.id}")
  @getAudienceUsers: (audience) =>
    @call('get', "insights/audiences/#{audience.id}/users")

  # campaigns
  @getCampaigns: (params) => # paginated
    @call('get', 'campaigns', params: params)
  @getCampaign: (id) =>
    @call('get', "campaigns/#{id}")
  @createCampaign: (campaign) =>
    @call('post', 'campaigns', body: campaign.export)
  @updateCampaign: (campaign) =>
    @call('patch', "campaigns/#{campaign.id}", body: campaign.export)
  @abortCampaign: (campaign) =>
    @call('patch', "campaigns/#{campaign.id}/abort")
  @deleteCampaign: (campaign) =>
    @call('delete', "campaigns/#{campaign.id}")
  @previewCampaign: (campaign, phoneNumber) =>
    @call('post', "campaigns/#{campaign.id}/preview", body: {userNativeChannelId: phoneNumber})
  @sendCampaign: (campaign) =>
    @call('post', "campaigns/#{campaign.id}/send")
  @getCampaignReport: (campaign) =>
    @call('get', "campaigns/#{campaign.id}/report")

import Condition from 'models/condition'
import PlatformApi2 from 'models/platform_api2'

export default class Audience
  @load: (id) ->
    PlatformApi2.getAudience(id)
      .then (data) => new Audience(data)
  @list: ->
    PlatformApi2.getAudiences()
      .then (data) -> data.map (audienceData) -> new Audience(audienceData)

  constructor: (data) ->
    data ||= {}
    @stageIds = []
    @channelIds = []
    @condition = new Condition()
    @update(data)

  update: (data = {}) ->
    @id = data.id if data.id?
    @stageIds = data.stageIds if data.stageIds?
    @channelIds = data.channelIds if data.channelIds?
    @condition = new Condition(data.condition) if data.condition?

  save: ->
    if @id?
      PlatformApi2.updateAudience(@)
    else
      PlatformApi2.createAudience(@)
        .then (data) => @update(data)

  delete: ->
    PlatformApi2.deleteAudience(@)

  getUsers: =>
    PlatformApi2.getAudienceUsers(@)
      .then (data) =>
        if data.status == 202
          return new Promise (resolve, reject) =>
            setTimeout(=>
              @getUsers().then(resolve).catch(reject)
            , 1000
            )
        total: data.total
        items: data.items

  resetCondition: ->
    @condition = new Condition()

  Object.defineProperties @prototype,
    timeUpdatedFormatted:
      get: ->
        return '' if !@timeUpdated
        moment(@timeUpdated).format(t('formats.dateTime'))
    export:
      get: ->
        id: @id
        stageIds: @stageIds
        channelIds: @channelIds
        condition: @condition.export


  import Audience from 'models/audience'
  import Campaign from 'models/campaign'
  import PlatformApi from 'models/platform_api'
  import WhatsappPromotionJob from 'models/whatsapp_promotion_job'
  import { confirm } from 'helpers'

  import AudienceDetails from './audience_details.vue'
  import AudienceBuilder from './audience_builder.vue'
  import AudienceList from './audience_list.vue'
  import BasicEditor from './basic_editor.vue'
  import CampaignPreviewSend from './campaign_preview_send.vue'
  import ProgressBar from './progress_bar.vue'
  import PromotionSent from './promotion_sent.vue'
  import WhatsappTemplateInstantiation from './whatsapp_template_instantiation.vue'
  import { PageHeader } from 'components/generic'

  export default
    data: ->
      audience: null
      campaign: null
      steps: [
        { name: 'Create campaign', completed: true }
        { name: 'Select audience', completed: true }
        { name: 'Set up message', completed: true }
        { name: 'Preview and send', completed: true }
      ]
      currentStep: @step || 0
      job: {status: 'UPDATING'}
      statusTimeout: null
      stopPolling: false

    created: ->
      @setCampaign()

    methods:
      setCampaign: ->
        @campaign = if @$route.params.campaignId
          await Campaign.load(@$route.params.campaignId)
        else
          new Campaign(channelId: @$root.selectedChannel.id)
        @audience = await @campaign.getAudience()
      previousStep: ->
        @currentStep -= 1
      nextStep: ->
        @currentStep += 1
      cancel: ->
        @cancelPolling()
        @$router.push(name: 'index')
      getStats: ->
        PlatformApi.WaTemplates.getStats(@job.id).then (data) =>
          @job.update(status: data.status, statistics: data.statistics)
          @audience.size.value = @job.statistics.audienceSize
          @audience.cost.value = @job.estimatedCostFormatted
          PlatformApi.WaTemplates.getAudience(@job.id).then (data) =>
            @audience.users = data.audience
      send: ->
        @job.status = 'SENDING'
        @currentStep += 1
        PlatformApi.WaTemplates.sendPromotionMessage(@job.id)
          .then => @pollSendingStatus()
          .catch => @job.status = 'FAILED'
      pollSendingStatus: ->
        PlatformApi.WaTemplates.getStats(@job.id).then (data) =>
          @job.update(status: data.status, statistics: data.statistics, messages: data.messages)
          return if @job.status == 'FAILED' || @stopPolling
          @statusTimeout = setTimeout(@pollSendingStatus, 2000)
      cancelPolling: ->
        clearTimeout(@statusTimeout)
        @stopPolling = true

    components:
      AudienceDetails: AudienceDetails
      AudienceBuilder: AudienceBuilder
      AudienceList: AudienceList
      BasicEditor: BasicEditor
      CampaignPreviewSend: CampaignPreviewSend
      PageHeader: PageHeader
      ProgressBar: ProgressBar
      PromotionSent: PromotionSent
      WhatsappTemplateInstantiation: WhatsappTemplateInstantiation


  import Campaign from 'models/campaign'
  import PlatformApi from 'models/platform_api'
  import WhatsappPromotionJob from 'models/whatsapp_promotion_job'

  import CampaignTableRow from './campaign_table_row.vue'
  import { Api2PaginationWithNumbers, Button, H3, Listbox, PageHeader, Pagination, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'
  import { ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    emits: ['digest-page']

    data: ->
      ChevronRightIcon: ChevronRightIcon
      Campaign: Campaign

    mounted: ->
      @loadCampaigns()

    methods:
      loadCampaigns: ->
        @$refs.pagination.load(page: 0)
      setTableHeaderPosition: ->
        @$nextTick =>
          headerTop = parseFloat(window.getComputedStyle(@$refs.header.$el).top)
          headerHeight = @$refs.header.offsetHeight
          @$refs.tableHeader.style.top = headerTop + headerHeight + 'px'
      remove: (index) ->
        @$refs.pagination.remove(index)
      channel: (job) ->
        @$root.channels.find((ch) => ch.uuid == job.channelId)
      channelLabel: (channel) ->
        "#{channel.label} | #{channel.nativeId || 'not connected'} | #{channel.environment}"
      phoneNumber: (job) -> # for sorting
        @channel(job)?.phone_number
      filterLabels: (job) -> # for sorting
        return '' unless job.config?.filters instanceof Array
        job.config.filters.map (filter) =>
          contextParameter = @$root.parameterByLabel(filter.field)
          (contextParameter?.humanizedLabel || contextParameter?.label || filter.field) +
            filter.operator +
            filter.value
        .join(' ')
      buildCampaign: ->
        @$router.push(name: 'newCampaign')

    components:
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      Button: Button
      H3: H3
      CampaignTableRow: CampaignTableRow
      Listbox: Listbox
      PageHeader: PageHeader
      Pagination: Pagination
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper

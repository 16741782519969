
  import Analytics from 'analytics.coffee'

  export default
    props:
      type:
        type: String
        default: 'default'
      size:
        type: String
        default: 'md'
      disabled:
        type: Boolean
        default: false
      href:
        type: String
        default: null
      icon:
        type: Function
        default: null
      spinning:
        type: Boolean
        default: false

    computed:
      typeClasses: ->
        switch @type
          when 'default'
            if @disabled
              'border border-gray-200 shadow-sm bg-gray-100 text-gray-500 focus:ring-MercuryBlue-500'
            else
              'border border-gray-300 shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:ring-MercuryBlue-500'
          when 'primary'
            if @disabled
              'border shadow-sm cursor-not-allowed bg-gray-500 text-white hover:bg-gray-600'
            else
              'border border-transparent shadow-sm bg-MercuryBlue-600 text-white hover:bg-MercuryBlue-700 hover:text-white focus:ring-MercuryBlue-800'
          when 'green'
            if @disabled
              'border shadow-sm cursor-not-allowed bg-gray-600 text-white hover:bg-gray-700'
            else
              'border border-transparent shadow-sm bg-MercuryGreen-600 text-white hover:bg-MercuryGreen-500 focus:ring-MercuryGreen-800'
          when 'danger'
            if @disabled
              'border shadow-sm cursor-not-allowed bg-gray-600 text-white hover:bg-gray-700'
            else
              'border border-transparent shadow-sm bg-MercuryRed-500 text-white hover:bg-MercuryRed-700 focus:ring-MercuryRed-800'
          when 'borderless'
            if @disabled
              'bg-white text-gray-500 hover:bg-gray-50 focus:bg-gray-50'
            else
              'bg-white text-gray-700 hover:bg-gray-50 focus:bg-gray-50'
      sizeClasses: ->
        switch @size
          when 'sm'
            'text-sm px-3 py-1'
          when 'md'
            'text-sm px-4 py-2'
          when 'lg'
            'text-base px-6 py-3'
      iconClasses: ->
        classes = switch @size
          when 'sm'
            'h-4 w-4 -ml-1 mr-1'
          when 'md'
            'h-5 w-5 -ml-1 mr-1'
          when 'lg'
            'h-6 w-6 -ml-1 mr-1'
        classes + if @spinning then ' animate-spin' else ''
      classes: ->
        "inline-flex items-center justify-center font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-inset #{@typeClasses} #{@sizeClasses}"

    methods:
      blur: ->
        @$refs.button.blur()
      track: ->
        try
          label = if @$refs.button
            @$refs.button.innerText
          else if @$refs.link
            @$refs.link.innerText
          Analytics.track(
            'Button clicked',
            button_label: label,
            url: window.location.href,
            is_botcore_2: Globals.projectIsBotcore2,
            project_role: Globals.projectRole
          )

import ContextUpdate from 'models/context_update.coffee'
import ParameterFilter from 'models/parameter_filter.coffee'
import WorkflowParameterMapping from 'models/workflow_parameter_mapping'
import WhatsappTemplateParameter from 'models/whatsapp_template_parameter.coffee'

export default class TemplateMessage
  @TYPES =
    Whatsapp:
      label: 'WhatsApp'
      attributes:
        templateName: ''
        templateNamespace: ''
        language: null
        headerParameters: []
        bodyParameters: []
        footerParameters: []
        buttonParameters: []

  constructor: (@host, data = {}) ->
    @type = data.type
    Object.entries(@attributes).forEach ([attribute, defaultValue]) =>
      @[attribute] = if defaultValue instanceof Array then defaultValue.slice() else defaultValue
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @attributeKeys.forEach (attribute) =>
      if ['headerParameters', 'bodyParameters', 'footerParameters', 'buttonParameters'].includes(attribute) && data[attribute]?
        @[attribute] = data[attribute].map (paramData) -> new WhatsappTemplateParameter(paramData)
      else
        @[attribute] = data[attribute] if data[attribute]?

  Object.defineProperties @prototype,
    label:
      get: ->
        TemplateMessage.TYPES[@type]?.label || @type
    attributes:
      get: ->
        TemplateMessage.TYPES[@type]?.attributes || {}
    attributeKeys:
      get: ->
        Object.keys(@attributes)
    export:
      get: ->
        obj = {type: @type}
        @attributeKeys.forEach (attribute) =>
          if ['headerParameters', 'bodyParameters', 'footerParameters', 'buttonParameters'].includes(attribute)
            obj[attribute] = @[attribute].map (parameter) -> parameter.export
          else
            obj[attribute] = @[attribute]
        obj


  import Audience from 'models/audience'
  import Campaign from 'models/campaign'

  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'
  import WhatsappTemplateDisplay from './whatsapp_template_display.vue'
  import WhatsappTemplateParametersEditor from './whatsapp_template_parameters_editor.vue'
  import { Button, Input, Listbox } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      campaign: Campaign
      audience: Audience

    emits: ['back', 'cancel', 'next']

    data: ->
      selectedTemplate: null
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon

    computed:
      templates: ->
        @$root.templates[@campaign.channelId]
          .filter (template) => template.language == @campaign.template.language
          .sortByString('name')

    created: ->
      @setLanguage()
      @setSelectedTemplate()

    methods:
      setLanguage: ->
        @campaign.template.language = @$root.selectedChannel.defaultLanguage
      setSelectedTemplate: ->
        @selectedTemplate = @templates.find (template) => template.name == @campaign.template.templateName
        @selectedTemplate ||= @templates[0]
        @updateTemplate()
      templateLabel: (template) ->
        return '&nbsp;' unless template?
        template.name.replaceAll('_', ' ').capitalize()
      updateTemplate: ->
        @campaign.template.templateName = @selectedTemplate.name
        @campaign.template.templateNamespace = @selectedTemplate.namespace
      next: ->
        # @campaign.save()
        @$emit('next')

    components:
      Button: Button
      DataTypeDependentInput: DataTypeDependentInput
      Input: Input
      Listbox: Listbox
      WhatsappTemplateDisplay: WhatsappTemplateDisplay
      WhatsappTemplateParametersEditor: WhatsappTemplateParametersEditor

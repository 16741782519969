import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import PlatformApi from 'models/platform_api'
import PlatformApi2 from 'models/platform_api2'
import BotbuilderApi from 'models/botbuilder_api'
import WhatsappTemplate from 'models/whatsapp_template'
import ContextParameterHelper from 'mixins/context_parameter_helper'
import { trackRouting } from 'helpers'

import CampaignBuilder from 'components/campaigns/campaign_builder.vue'
import CampaignList from 'components/campaigns/campaign_list.vue'

initCampaignsApp = (element) ->
  routes = [
    name: 'index',
    path: '/',
    component: CampaignList,
  ,
    name: 'newCampaign',
    label: 'New campaign'
    path: '/',
    component: CampaignBuilder,
  ,
    name: 'campaign',
    path: '/:campaignId',
    component: CampaignBuilder,
  ].map (item) -> Object.assign(item, path: '/projects/:projectId/:stageLevel/campaigns' + item.path)

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  createApp(
    mixins: [ContextParameterHelper]

    data: ->
      elementData = JSON.parse(element.getAttribute('data'))

      channels: []
      currentBot: null
      contextParameters: []
      selectedChannel: null
      templates: {}
      languages: elementData.languages

    computed:
      currentRoute: ->
        return {} unless @$route.name?
        @$router.options.routes.find (route) => route.name == @$route.name

    watch:
      '$route.name': -> @setBreadcrumbs()

    created: ->
      @loadBot()
      @loadContextParameters()
      @loadChannels()

    methods:
      loadBot: ->
        Promise.all([
          BotbuilderApi.getBots()
          PlatformApi2.getActiveBotId()
        ]).then ([bots, activeBotId]) =>
          @currentBot = bots.find (bot) -> bot.id == activeBotId
          @currentBot.loadConfig()
      loadContextParameters: ->
        @contextParameters = await BotbuilderApi.getContextParameters()
      loadChannels: ->
        PlatformApi2.getChannels().then (channels) =>
          @channels = channels.filter((ch) -> ch.type == 'WHATSAPP').sortByString('label')
          @selectedChannel = @channels[0]
          @channels.forEach (channel) =>
            channel.getTemplates().then (templates) =>
              @templates[channel.id] = templates.filter (t) -> t.status.toUpperCase() == 'APPROVED'
      parameterByLabel: (keyOrLabel) ->
        return '' unless keyOrLabel
        @contextParameters.find (parameter) -> parameter.key == keyOrLabel
      setBreadcrumbs: ->
        if @$route.name == 'index'
          window.breadcrumbs.strip()
        else
          window.breadcrumbs.strip().append(text: @currentRoute.label)

    components:
      CampaignBuilder: CampaignBuilder
      CampaignList: CampaignList

    template: '
      <div class="sm:px-6 lg:px-8">
        <router-view/>
      </div>
    '
  )
    .use(router)
    .mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('campaigns-app')
      initCampaignsApp(element)

<template>
  <Disclosure v-if="!legacyMenu" as="nav" class="bg-BlackOlive fixed inset-x-0 z-30" v-slot="{ open }">
    <div class="px-4 lg:px-6 xl:px-8">
      <div class="flex items-center justify-between h-16">

        <div class="tw-hidden md:flex items-center">
          <a class="w-1/4" :href="projectHomeUrl">
            <div style="width: 212px"></div>
            <InlineSvg class="w-full pointer-events-none" type="image/svg+xml" :src="logo"/>
          </a>
          <div class="lg:ml-4 xl:ml-10 flex items-baseline lg:space-x-2">
            <template v-for="menuItem in menu" :key="menuItem.label">
              <a
                v-if="menuItem.display"
                :href="menuItem.href"
                class="px-2 xl:px-3 py-2 group text-white rounded-md inline-flex items-center text-sm xl:text-base font-light hover:bg-EerieBlack focus:outline-none hover:no-underline hover:text-white focus:no-underline focus:text-white"
                :class="{'bg-MediumBlack': active(menuItem)}"
              >
                <span class="whitespace-nowrap">{{ menuItem.label }}</span>
              </a>
            </template>
          </div>
        </div>

        <div class="w-full sm:w-1/2 md:w-1/3 max-w-xs mr-2 pr-2 py-2">
          <ProjectDropdown :projects="projects"/>
        </div>

        <div class="flex items-baseline">
          <ActiveToggle v-if="checkedIn != null" v-model="checkedIn"/>
        </div>

        <div class="flex items-end items-baseline space-x-4">
          <!--
          <div class="xl:pr-4 py-2">
            <Notifications />
          </div>
          -->
          <button @click="toggleWidget" class="xl:mr-4">
            <ChatAltIcon class="h-5 w-5 xl:h-6 xl:w-6 text-gray-400 hover:text-white"/>
          </button>
          <ProfileDropdown />
        </div>

        <div class="hidden -mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="hidden md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <template v-for="(item, itemIdx) in navigation" :key="item">
          <template v-if="(itemIdx === 0)">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <a href="#" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-light">{{ item }}</a>
          </template>
          <a v-else href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-light">{{ item }}</a>
        </template>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="coffee">
  import { Disclosure, DisclosureButton, DisclosurePanel, } from '@headlessui/vue'
  import InlineSvg from 'vue-inline-svg'
  import {
    AdjustmentsIcon, AtSymbolIcon,
    BookOpenIcon,
    ChatAltIcon, ChatAlt2Icon, ChatIcon, ClipboardListIcon, ClockIcon,
    DatabaseIcon, DocumentIcon,
    FastForwardIcon,
    IdentificationIcon,
    LibraryIcon, LightningBoltIcon,
    MailIcon, MenuIcon,
    PresentationChartLineIcon, PuzzleIcon,
    ShareIcon, ShieldExclamationIcon,
    TemplateIcon, TerminalIcon,
    UserGroupIcon,
    XIcon,
  } from '@heroicons/vue/outline'
  import {
    ProjectDropdown,
    ProfileDropdown,
    Notifications,
    ActiveToggle,
  } from '.'
  import staticLogo from 'images/logo_main_menu.svg'

  projectMenu = (projectId, stageLevel, display) ->
    return [] if !projectId?
    stageLevel = 'live' if !stageLevel
    [
      {
        label: 'Inbox',
        href: Routes.inbox3_project_path(projectId, stageLevel),
        display: display.inbox,
      },
      {
        label: 'Automation',
        href: Routes.automation_project_path(projectId, stageLevel),
        display: display.automation,
      },
      {
        label: 'Analytics',
        href: Routes.analytics_project_path(projectId, stageLevel),
        display: display.analytics,
      },
      {
        label: 'Campaigns',
        href: Routes.campaigns_project_path(projectId, stageLevel),
        display: display.campaigns,
      },
      {
        label: 'Project settings',
        href: Routes.settings_project_path(projectId, stageLevel),
        display: display.settings,
      }
    ]

  profileMenu = (display) ->
    [
      label: 'Profile'
      url: Routes.profile_path()
      display: true,
    ,
      label: 'Account settings'
      url: Routes.organization_path(Globals.currentUser.organizationId)
      display: display.organization_settings,
    ,
      label: 'Customer service'
      url: Routes.service_organizations_path()
      display: display.service,
    ,
      label: 'Administration'
      url: Routes.rails_admin_path()
      display: display.admin,
    ,
      label: 'Privacy policy'
      url: 'https://mercury.ai/de/privacy-policy'
      newTab: true
      display: true
    ,
      label: 'Sign out'
      url: Routes.destroy_user_session_path(id: Globals.currentUser.id, _options: true)
      rel: 'nofollow'
      dataMethod: 'delete'
      display: true,
    ]

  export default
    data: ->
      turbolinksPermanentSelector: 'main-menu-app'
      legacyMenu: null
      menu: []
      profileMenu: []
      projects: []
      currentProject: {}
      mayCreateProject: false
      checkedIn: null
      projectHomeUrl: Routes.root_path()
      logo: staticLogo

    created: ->
      @setMenu()
      $(window).on 'turbolinks:load', @setMenu

    methods:
      active: (menuItem) ->
        return true if window.location.pathname.match("^#{menuItem.href}")
        false
      setMenu: ->
        @legacyMenu = $('#main-menu-data').data('legacy-menu')
        menuItemsDisplay = $('#main-menu-data').data('menu-items-display')
        @projects = $('#main-menu-data').data('projects')
        @menu = projectMenu(Globals.projectId, Globals.stageLevel, menuItemsDisplay)
        @profileMenu = profileMenu(menuItemsDisplay)
        @currentProject = if Globals.projectId?
          @projects.find (proj) -> proj.id == Globals.projectId
        else
          {}
        @mayCreateProject = $('#main-menu-data').data('may-create-project')
        @checkedIn = $('#main-menu-data').data('checked-in')
        @projectHomeUrl = if Globals.projectId && Globals.stageLevel
          Routes.project_path(Globals.projectId, Globals.stageLevel)
        else
          Routes.root_path()
      toggleWidget: ->
        document.querySelector('#global-widget .open-toggle, #global-widget .close-toggle').click()

    components:
      ChatAltIcon: ChatAltIcon
      Disclosure: Disclosure,
      DisclosureButton: DisclosureButton,
      DisclosurePanel: DisclosurePanel,
      InlineSvg: InlineSvg,
      MenuIcon: MenuIcon,
      XIcon: XIcon,
      ProjectDropdown: ProjectDropdown,
      ProfileDropdown: ProfileDropdown,
      Notifications: Notifications,
      ActiveToggle: ActiveToggle,
</script>

import Audience from 'models/audience'
import PlatformApi2 from 'models/platform_api2'
import Status from 'models/status'
import TemplateMessage from 'models/template_message'

export default class Campaign
  @list: (params = {}) ->
    PlatformApi2.getCampaigns(params)
      .then (data) ->
        total: data.total
        items: (data.items || []).map (campaignData) -> new Campaign(campaignData)

  @load: (id) ->
    PlatformApi2.getCampaign(id)
      .then (data) => new Campaign(data)

  constructor: (data) ->
    data ||= {}
    # set default values
    @label = ''
    @description = ''
    @status = 'draft' # | 'scheduled' | 'sending' | 'waiting' | 'aborted' | 'finished'
    @audienceId = ''
    @fullAudienceSize = 0 # the original audience size ignoring the limit
    @fullCost = 0 # the estimated full cost if all users in the audience are reached
    @actualAudienceSize = 0 # the actual audience size including the limits
    @actualCost = 0 # the actual estimated cost including the limits
    @template = new TemplateMessage(@, type: 'Whatsapp')
    @channelId = ''
    @channelLabel = ''
    @nativeChannelId = ''
    @audienceSizeLimit = 0
    @costLimit = 0
    @note = ''
    @timeUpdated = ''
    @timeCreated = ''
    @report = {}
    # set data
    @update(data)

  update: (data) ->
    return if !data?
    @id = data.id if data.id?
    @label = data.label if data.label?
    @description = data.description if data.description?
    @status = data.status if data.status?
    @audienceId = data.audienceId if data.audienceId?
    @fullAudienceSize = data.fullAudienceSize if data.fullAudienceSize?
    @fullCost = data.fullCost if data.fullCost?
    @actualAudienceSize = data.actualAudienceSize if data.actualAudienceSize?
    @actualCost = data.actualCost if data.actualCost?
    @template = new TemplateMessage(@, data.template) if data.template?
    @channelId = data.channelId if data.channelId?
    @channelLabel = data.channelLabel if data.channelLabel?
    @nativeChannelId = data.nativeChannelId if data.nativeChannelId?
    @audienceSizeLimit = data.audienceSizeLimit if data.audienceSizeLimit?
    @costLimit = data.costLimit if data.costLimit?
    @note = data.note if data.note?
    @timeCreated = data.timeCreated if data.timeCreated?
    @timeUpdated = data.timeUpdated if data.timeUpdated?
    @report = {}

  save: =>
    if @id?
      PlatformApi2.updateCampaign(@)
    else
      PlatformApi2.createCampaign(@)
        .then (data) => @update(data)

  delete: ->
    PlatformApi2.deleteCampaign(@)

  abort: ->
    PlatformApi2.abortCampaign(@)
      .then (data) => @update(data)

  preview: (phoneNumber) ->
    PlatformApi2.previewCampaign(@, phoneNumber)

  send: ->
    PlatformApi2.sendCampaign(@)

  getReport: ->
    PlatformApi2.getCampaignReport(@)
      .then (data) =>
        @report = data
        @report.status = new Status(data.statusModel)

  getAudience: ->
    if @audienceId
      Audience.load(@audienceId)
    else
      Promise.resolve(new Audience(channelIds: [@channelId]))

  Object.defineProperties @prototype,
    timeCreatedFormatted:
      get: ->
        return '' if !@timeCreated
        moment(@timeCreated).format(t('formats.dateTime'))
    timeUpdatedFormatted:
      get: ->
        return '' if !@timeUpdated
        moment(@timeUpdated).format(t('formats.dateTime'))
    export:
      get: ->
        id: @id
        label: @label
        description: @description
        audienceId: @audienceId
        template: @template.export
        channelId: @channelId
        audienceSizeLimit: @audienceSizeLimit
        costLimit: @costLimit

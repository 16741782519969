
  import WhatsappTemplate from 'models/whatsapp_template'
  import WhatsappTemplateParameter from 'models/whatsapp_template_parameter'

  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Input } from 'components/generic'

  export default
    props:
      modelValue: WhatsappTemplateParameter
      template: WhatsappTemplate
      section: String

    data: ->
      parameter: @modelValue

    computed:
      inputFieldCount: ->
        c = 1
        c++ if @parameter.hasFallback
        c++ if @parameter.hasFilename
        c
      inputFieldWrapperClass: ->
        switch @inputFieldCount
          when 1 then 'max-w-md'
          when 2 then 'space-y-2 2xl:space-y-0 2xl:grid 2xl:grid-cols-2 2xl:gap-4'
          when 3 then 'space-y-2 3xl:space-y-0 3xl:grid 3xl:grid-cols-3 3xl:gap-4'

    methods:
      update: ->
        @$emit('update:modelValue', @parameter)

    components:
      Input: Input
      UniversalInput: UniversalInput

<template>
  <div class="mt-12">
    <div v-if="action == 'home' && !projectIsKim" class="flex justify-end items-end">
      <Button type="primary" @click="buildTemplate">
        <ChevronRightIcon class="h-5 w-5 -ml-1 mr-1"/>
        Create new template
      </Button>
    </div>

    <TemplateList
      v-if="action == 'home'"
      :channel="channel"
      :templates="templates"
      @edit="editTemplate"
    />

    <TemplateBuilder
      v-if="action == 'builder'"
      :template="currentTemplate"
      :channel="channel"
      @add="addTemplate"
      @cancel="cancelTemplate"
      @home="goHome"
    />
  </div>
</template>

<script lang="coffee">
  import PlatformApi from 'models/platform_api.coffee'
  import WhatsappChannel from 'models/whatsapp_channel.coffee'
  import WhatsappTemplate from 'models/whatsapp_template.coffee'
  import { confirm } from 'helpers'

  import TemplateBuilder from './template_builder.vue'
  import TemplateList from './template_list.vue'
  import { Button, Listbox } from 'components/generic'
  import { ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      channel: WhatsappChannel

    data: ->
      action: 'home'
      templates: []
      currentTemplate: null
      projectIsKim: Globals.projectUuid == '5931a71f-9988-42a9-bfa4-0f5e5d225917'

    created: ->
      if @projectIsKim # KIM 2.0
        $.getJSON Routes.kim_whatsapp_templates_project_path(Globals.projectId, Globals.stageLevel), (templates) =>
          @templates = ObjectProcessor.clone(templates).map (templateData) -> new WhatsappTemplate(templateData)
        return
      @templates = await @channel.getTemplates()

    methods:
      channelLabel: (channel) ->
        "#{channel.name} | #{channel.phone_number} | #{channel.stage_label}"
      buildTemplate: ->
        @currentTemplate = new WhatsappTemplate(
          channelId: @channel.uuid
          components: [type: 'BODY', text: '']
        )
        @action = 'builder'
      editTemplate: (template) ->
        @currentTemplate = template
        @action = 'builder'
      cancelTemplate: ->
        return unless await confirm('Do you really want to discard this template?')
        @action = 'home'
      addTemplate: (template) ->
        @templates.push(template)
      goHome: ->
        @action = 'home'

    components:
      Button: Button
      ChevronRightIcon: ChevronRightIcon
      Listbox: Listbox
      TemplateBuilder: TemplateBuilder
      TemplateList: TemplateList
</script>


  import { RefreshIcon } from '@heroicons/vue/outline'

  export default
    props:
      audience: Object

    data: ->
      loading: false

    components:
      RefreshIcon: RefreshIcon

import Channel from 'models/channel.coffee'
import PlatformApi from 'models/platform_api.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'
import WhatsappTemplate from 'models/whatsapp_template'

export default class WhatsappChannel extends Channel
  setConfig: (data = {}) ->
    @email = data?.settings?.business?.profile?.email
    @address = data?.settings?.business?.profile?.address
    @whatsappDescription = data?.settings?.business?.profile?.description
    @website = data?.settings?.business?.profile?.websites?[0]
    @about = data?.settings?.profile?.about?.text
    @photoUrl = data?.settings?.profile?.photo?.link

  fetchChannels: (channelIdsString) ->
    PlatformApi.Webapp.get360DialogChannels(channelIdsString)

  fetchCredentialsAndConnect: (channelId, phoneNumber) ->
    PlatformApi.Webapp.get360DialogCredentials(channelId)
      .then (data) =>
        @connect(phoneNumber: phoneNumber, apiKey: data.api_key)

  uploadPhoto: (file) ->
    if file == null
      PlatformApi2.deleteChannelAsset(@, 'photo').then (data) =>
        @photoUrl = ''
    else
      PlatformApi2.uploadChannelAsset(@, 'photo', file).then (data) =>
        @photoUrl = data.url

  getTemplates: ->
    return Promise.resolve([]) if !@nativeId
    PlatformApi.Webapp.getWabaTemplates(@nativeId).then (data) =>
      data.map (templateData) -> new WhatsappTemplate(templateData)

  Object.defineProperties @prototype,
    config:
      get: ->
        settings:
          business:
            profile:
              email: @email
              address: @address
              description: @whatsappDescription
              websites: [@website].compact()
          profile:
            about:
              text: @about
